import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/dinny/logoig.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import getData from '../params/getdata'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { pw } from '../params'

let cmain = '#B99225'
let black = 'rgb(38,38,38)'

let id = 'rizky-dea'
let inisial_co = 'Rizky'
let inisial_ce = 'Dea'

let lengkap_co = (<>Rizky Akbar, S.STP., M.Tr.I.P</>)
let lengkap_ce = (<>Deane Medya Rismayandari, S.IP., M.Si</>)

let bapak_co = 'Bapak Drs. H. Suryadi'
let ibu_co = 'Ibu Hj. Israwati, S.KM'
let bapak_ce = "Bapak Dr. H. Tahyudin, M.Pd"
let ibu_ce = "Ibu Hj. Nina Tasminah, S.Pd., M.M"

let ig_co = "14rizkyakbar22"
let ig_ce = "deanemr"

let foto_ce = pw("dea-rizky", "Individual%202.jpg")
let foto_co = pw("dea-rizky", "Individual%201.jpg")
console.log(foto_ce)
let waktunikah = "12/24/2020"

let modal = pw("dea-rizky", "modal.jpg")
let openlogo = pw('dea-rizky', "logo.png")

let gmaps = "https://www.google.com/maps?q=5.2105974,96.6953307&z=17&hl=en"
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3973.33086784544!2d96.69314201476436!3d5.210597396219209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNcKwMTInMzguMiJOIDk2wrA0MSc0My4yIkU!5e0!3m2!1sen!2sid!4v1607227783592!5m2!1sen!2sid"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=Mm5qOTludWJ2YTRna3FtdDdvaTA4NzBiY28gYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    async componentDidMount() {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            let comment=await getData('kepada:"rizky-dea"')
            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }


    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw("dea-rizky","music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "rizky-dea", pesan:"${this.pesan.current.value}",alamat: ""`
                              )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, hide, submitted, err, sesi, comment } = this.state
        let slider = []
        let slide = [pw("dea-rizky", "DSC02661.jpg"),
        pw("dea-rizky", "DSC09373.jpg"),
        pw("dea-rizky", "DSC09680.jpg"),
        pw("dea-rizky", "IMG_7593.jpg"),
        pw("dea-rizky", "IMG_7894.jpg"),
        pw("dea-rizky", "IMG_8136.jpg"),
        pw("dea-rizky", "IMG_8188.jpg"),
        pw("dea-rizky", "IMG_8320.jpg"),
        pw("dea-rizky", "IMG_8368.jpg"),
        pw("dea-rizky", "IMG_8439.jpg"),

        ]
        slide.map(v => {
            slider.push(gambar(v, 95,
                'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=480'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        {query ? (<> Kepada Yth :<br />{query}</>) : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Buka Undangan
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center cblack px-3">
                                            “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.” <br /><br />(Ar-Rum: 21)
                    </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw("asset", "aceh.svg")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain,fontFamily:'Marck Script, cursive',fontSize:"32px" }}>
                                            Tueng Dara Baroe <br /> {inisial_co} & {inisial_ce}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12  col-lg-6 h-100' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2 h-100'>
                                                <div className="h-100 w-100">
                                                    <Item>
                                                        <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                            {inisial_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <Col xs={6}>
                                                            <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                        </Col>
                                                    </Item>
                                                    <Item>
                                                        <h1 className="py-3 w-100 text-center" style={{
                                                            fontSize: '24px',
                                                            fontFamily: "'Marck Script', cursive", color: cmain
                                                        }}>
                                                            {lengkap_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                            <b>Putra Pertama dari:</b><br />
                                                            {bapak_co}
                                                            <br />
                                                        &<br />
                                                            {ibu_co}
                                                        </p>
                                                    </Item>
                                                    <Item>
                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                    </Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=' col-xs-12 col-lg-6 mt-3 mt-lg-0 ' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri Kedua dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                                    </Item>
                                    {/* <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">12</span> DES 2020
                      </b>
                                        </p>
                                    </Item> */}
                                    <Item>
                                        <p className="fs20 col-md-10" style={{ color: cmain }}>
                                            <b style={{fontFamily:'Marck Script, cursive', fontSize: '32px' }}>Akad Nikah </b><br />
                                            <span className="cblack fs16">
                                                Telah Dilaksanakan<br />
                                                <b>Sabtu, 06 Juni 2020<br /><br />
                                                D'Lontar Cafe <br/>Kota Serang - Banten </b><br />

                                            </span>
                                            {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                                        </p>

                                        <div className="col-8 " style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-md-3 col-md-10" style={{ color: cmain }}>
                                            <b  style={{fontFamily:'Marck Script, cursive', fontSize: '32px' }}>Resepsi<br />
                                            Tueng Dara Baroe</b><br />

                                            <span className="cblack fs16">
                                                <b>Kamis, 24 Desember 2020
                                                <br />
                                                9.30 s/d selesai<br /><br />
                                                Kediaman Mempelai Pria<br />
                                                </b>
                                                Gampong Bireuen Meunasah Reuleut, Kecamatan Kota Juang Kabupaten Bireuen, Aceh


                                               </span>


                                        </p>
                                    </Item>
                                    <Container className="text-center py-5 dinny" id="dinny">

                                        <>
                                            <Item>
                                                <h1 style={{
                                                    fontFamily:'Marck Script, cursive',
                                                    fontSize: '36px',
                                                    color: cmain
                                                }} data-aos={`fade-right`} data-aos-duration="2000">
                                                    Susunan Acara
        </h1>
                                            </Item>

                                            {
                                                [
                                                    // { waktu: "	09.00 -10.00	", acara: "	Akad Nikah", icon: "	https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" },
                                                    { waktu: "	9.30-10.30 ", acara: "	prosesi pernikahan purna praja dharma astabratha", icon: "	https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg	" },
                                                    { waktu: "	10.30-11.00", acara: "sesi foto", icon: "	https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg	" },

                                                    { waktu: "	11.30-12.00", acara: "acara adat dueak bisan", icon: pw("asset", "aceh1.png") },
                                                    { waktu: "12.00-02.00", acara: "memberi selamat kepada pengantin", icon: pw("asset", "salaman.png") },
                                                    { waktu: "	02.00-selesai", acara: " pet boh trueng  ", icon: pw("asset", "aceh2.png") },

                                                ].map((v, i) => {
                                                    i++
                                                    return (
                                                        <Row className="py-2" data-aos={`fade-left`} data-aos-duration="2000">
                                                            <Col className={i % 2 ? "order-md-1" : "order-md-2"}>
                                                                <Row >
                                                                    <Col md={3} xs={3} className={`order-1 ${i % 2 ? "order-md-2" : "order-md-1"}`}>
                                                                        <img src={v.icon} className="img-fluid w-100 px-1 px-md-2 my-auto" />
                                                                    </Col>
                                                                    <Col xs={8} className={`order-1 text-left ${i % 2 ? "order-md-1 text-md-right" : "order-md-2 text-md-left"}`}>
                                                                        <p className=" fs16" style={{ color: cmain }}>
                                                                            <b>
                                                                                {v.waktu}
                                                                            </b><br />
                                                                            {v.acara}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col className={`d-none d-md-block ${i % 2 ? "order-md-2" : "order-md-1"}`}>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }

                                        </>
                                    </Container>

                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.whatismyip-address.com/nordvpn-coupon/"></a></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="text-center dinny">
                                    <Item>
                                        <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                                            <h1 className="fs30"
                                                style={{

                                                    fontFamily: "'Marck Script', cursive",
                                                    color: '#B99225'
                                                }}>
                                               Protokol Kesehatan 
                          </h1>
                                            <Item>
                                                <Col xs={12}>
                                                    <p className="cblack text-center w-100 fs16">
                                                        Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Gunakan Masker
                        </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Jaga Jarak
                      </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'salaman.png')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Cukup Bersalaman tanpa Bersentuhan
                      </p>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </Col>
                                    </Item>

                                    <Item>
                                        <Col>
                                        </Col>

                                    </Item>

                                </Container>



                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Ucapan & Doa
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container className="py-5 px-md-5 col-md-8 px-3"
                                    style={{ color: '#171717', fontFamily: 'Patrick Hand, cursive' }}
                                >

                                    <Row className="position-relative px-5 py-5" style={{ backgroundColor: 'rgba(255,255,255,0.1)' }}>
                                        <h1 className="peri w-100 text-center">
                                            Ucapan dan doa
                  </h1>
                                        {/* <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="mirror img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="flip180-m img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className=" flip180  img-fluid w-100" />
                                        </Col> */}
                                        <Row style={{ maxHeight: '50vh', overflowY: 'scroll' }} className="scroller w-100">
                                            {comment ? comment.map((v, i) => {
                                                return (
                                                    <Row className="w-100 text-center mx-auto">
                                                        <Col></Col>
                                                        <Col xs={12} md={6} className="py-2">
                                                            <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                                                " {v.pesan} " <br /> - {v.dari} -
                                                            </p>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                )
                                            }) : false

                                            }
                                        </Row>

                                    </Row>

                                </Container>
                                <Container classname="py-3" style={{ color: cmain }}>
                                    <p className="text-center w-100" style={{ fontFamily: 'Patrick Hand, cursive' }} >
                                        Terima Kasih
                                     </p>
                                    <h1 className="text-center w-100" style={{ fontFamily: 'Parisienne, cursive', }}>
                                        {inisial_co} & {inisial_ce}
                                    </h1>
                                </Container>



                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

